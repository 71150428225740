import React, { useState } from "react";
import { IConfig } from "../../Interfaces/IConfig";
import { CustomCircularProgress } from "../generic/CustomCircularProgress";
import { LoginMain } from "./LoginMain";
import { LoginF2A } from "./LoginF2A";
import { LoginData } from "./LoginData";

interface IProps {
    configObject: IConfig;
    msgParent: string | null;
}



export const LoginDriver:React.FC<IProps> = ({msgParent,configObject}) => {
    const [currentPage,setCurrentPage] = useState(0);
    const [msg,setMsg] = useState((msgParent === null) ? "" : msgParent);
    //
    const [isLoading, setIsLoading] = useState(false);
    const [tmpSessionToken, setTmpSessionToken] = useState<string>("");
    const [idContact, setIdContact] = useState<number>(-1);


    async function sendPost(sendObject:any,callAfterSave:Function,errorFunction:Function) {
        setIsLoading(true);

        await fetch("/api/oauth", {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(sendObject)
        })
        .then(res => {
            if (res.status === 200) {
                setIsLoading(false);
                return res.json();
            } else {
                throw Error(String(res.status))
            }
        })
        .then(res => {
            setMsg("");
            setIsLoading(false);
            callAfterSave(res)
        })
        .catch(error => {
            if (String(error).includes("401")) {
                setMsg("Die Anmeldedaten scheinen nicht korrekt zu sein")

            } else if (String(error).includes("403")) {
                setMsg(`Ihr Konto wurde deaktiviert!`);
            } else {
                setMsg(`Es ist ein unbekannter Fehler aufgeretten. Möglicherweise ist der Dienst vorübergehend offline.`);
            }
            setIsLoading(false);
            errorFunction();
        })
    }



    if (isLoading) {
        return <CustomCircularProgress/>
    }
    else if (currentPage === 0) {
        return (
            <LoginMain
                configObject={configObject}
                msg={msg}
                setMsg={setMsg}
                setIdContact={setIdContact}
                setTmpSessionToken={setTmpSessionToken}
                setCurrentPage={setCurrentPage}
                sendFunction={sendPost}
            />
        )
    }
    else if (currentPage === 1) {
        return (
            <LoginF2A
                configObject={configObject}
                msg={msg}
                setMsg={setMsg}
                idContact={idContact}
                tmpSessionToken={tmpSessionToken}
                setCurrentPage={setCurrentPage}
                sendFunction={sendPost}
            />
        )
    }

    /* ACHTUNG: 
        Bei Validierung der Daten durch den Mantangten, setzt LoginF2A den Key 
        passwordResetRequired im Sessstion auf true.
        ist passwordResetRequired und isLogged jeweils true, 
        verweist der SecurityDriver auf die Komponente <LoginData/> 

    */
    else if (currentPage === 2) {
        return (<>AUTP</>)
    }
    else {
        return(
            <>FEHLER!</>
        )
    }

}