import React, { useState } from "react";
import { IConfig } from "../../Interfaces/IConfig";
import { Alert, Box, Button, Grid, TextField } from "@mui/material";

interface IProps {
    msg: string;
    setMsg: Function;
    idContact: number;
    tmpSessionToken: string;
    setCurrentPage: Function;
    sendFunction: Function;
    configObject: IConfig;
}


export const LoginF2A:React.FC<IProps> = ({
    msg, setMsg, 
    idContact, tmpSessionToken, setCurrentPage,
    sendFunction,
    configObject,
}) => {
    const [code, setCode] = useState<string>("");

    const handleChangeCode = (input: string) => {
        const regex = /^[0-9]*$/;
        if (regex.test(input)) {
            setCode(input)
        }
    }

    const handleAfterSave = (res:any) => {
        sessionStorage.setItem('SessionToken', res["SessionToken"]);
        sessionStorage.setItem('idContact', res["idContact"]);
        sessionStorage.setItem('role', res["Role"]);
        sessionStorage.setItem('scope', res["Scope"]);
        sessionStorage.setItem('passwordResetRequired', res["passwordResetRequired"]);
        
        window.location.reload();
        /*
        #if (Boolean(res["passwordResetRequired"])) {
        #    setCurrentPage(2);
        } else {
            window.location.reload();
        }
        */
    }


    const handleSubmit2FA = (e: React.SyntheticEvent) => {
        e.preventDefault();
        sendFunction(
            {"idContact": idContact, "TempSessionToken": tmpSessionToken, "2FACode": code} as any,
            handleAfterSave,
            () => {setCode("")}
        )
    }


    return(
        <form onSubmit={handleSubmit2FA} autoComplete="on">
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{minHeight: '100vh'}}
            >
                <img width={250} src={`data:${configObject.logo_mime};base64,${configObject.logo}`}/>
                <Box sx={{mt: 4}}/>
                <Grid item xs={3} style={{width: 400}}>
                    <Box sx={{textAlign: "center"}}>
                        Die Sicherheit Ihrer Daten ist uns ein wichtiges Anliegen.
                        Um sicherzustellen, dass Ihr Konto optimal geschützt ist, verwenden
                        wir die 2-Faktor-Authentifizierung an.
                        <br/>
                        <br/>
                        Sie erhalten Ihr <b>Authentifizierungsschlüssel</b> unter
                        der bei uns hinterlegten <b>E-Mail-Adresse</b> oder per SMS
                    </Box>
                </Grid>
                <Box sx={{mt: 6}}/>
                <Grid item xs={3} style={{minWidth: 300}}>
                    <TextField
                        label="Authentifizierungsschlüssel"
                        value={code}
                        fullWidth
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeCode(String(event.target.value))}
                        //onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCode(String(event.target.value))}
                        required
                    />
                </Grid>

                <Box sx={{mt: 3}}/>
                <Grid item xs={3} style={{minWidth: 300}}>
                    <div style={{float: 'right'}}>
                        <Button size="small" variant="contained" color="primary" type="submit">Anmelden</Button>
                    </div>
                </Grid>

                <Box sx={{mt: 3}}/>
                <Grid item xs={3} style={{minWidth: 300}}>
                    {(msg !== "") &&
                        <Alert severity="error">
                            {msg}
                        </Alert>
                    }
                </Grid>

            </Grid>
        </form>
    )
}