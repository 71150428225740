import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Alert, ThemeProvider, createTheme } from '@mui/material';


import { deDE as dataGridDeDE } from '@mui/x-data-grid';
import { deDE as coreDeDE } from '@mui/material/locale';
import { deDE } from '@mui/x-date-pickers/locales';
import { useFetch } from './hooks/useFetch';
import { IConfig } from './Interfaces/IConfig';
import { CustomCircularProgress } from './components/generic/CustomCircularProgress';
import SecurityDriver from './components/core/SecurityDriver';
import { PrivatePageRouter } from './components/core/PrivatePageRouter';



function App() {
  const [config, setConfig, wasSuccessfullyConfig] = useFetch<IConfig | undefined>("/config");
  //
  const [theme,setTheme] = useState(createTheme({
      palette: {
        primary: {
          main: '#AF9F66',
        },
        secondary: {
          main: '#504C48',
        },
        text: {
          primary: '#504C48',
        },
      },
    },
    deDE, // x-date-pickers translations
    dataGridDeDE, // x-data-grid translations
    coreDeDE, // core translations
  )
);


  useEffect(() => {
    if (config !== undefined) {
      setTheme(createTheme({
        palette: {
          primary: {
            main: config.ci_color_primary,
          },
          secondary: {
            main: config.ci_color_secondary,
          },
          text: {
            primary:  config.ci_color_text,
          },
        },
        },
        deDE, // x-date-pickers translations
        dataGridDeDE, // x-data-grid translations
        coreDeDE, // core translations
      )
    );


      var link = document.createElement('link');
      
      link.id = 'dynamic-favicon';
      link.rel = 'shortcut icon';
      link.href = `data:image/x-icon;base64,${config.favicon}`;

      var oldLink = document.getElementById('dynamic-favicon');
      if (oldLink) {
        document.head.removeChild(oldLink);
      }
      document.head.appendChild(link);
      document.title = `Mandantenportal - ${config.company_name}`;
    }
  },[config])


  if (!wasSuccessfullyConfig || (config !== undefined && config.App.find(x => x === "dino_pro") === undefined)) {
    return(
      <Alert severity='warning'>
        Die Lizenz von DiNo konnte nicht überprüft werden!
      </Alert>
    )
  }
  else if (config === undefined) {
    return <CustomCircularProgress/>
  }
  else { 
    return (
      <ThemeProvider theme={theme}>
        <SecurityDriver configObject={config}>
          <PrivatePageRouter configObject={config}/>
        </SecurityDriver>
     </ThemeProvider>
    );
  }

}

export default App;
