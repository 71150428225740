import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper, TextField } from "@mui/material";
import { grey } from "@mui/material/colors";
import { IDraftMessage, IDraftMessageFullObject } from "../../../../Interfaces/IDraftMessage";
import { ILegalTransactionFullObject } from "../../../../Interfaces/ILegalTransaction";
import { truncate } from "fs";
import { CustomCircularProgress } from "../../../generic/CustomCircularProgress";
import { uploadFetch } from "../../../../hooks/useFetch";
import { DocumentOverview } from "../../Document/DocumentOverview";
import { IDocument } from "../../../../Interfaces/IDocument";


interface IProps {
    legalTransactionObject: ILegalTransactionFullObject;
    idDraftMessageParent: number | null;
    setIdDraftMessageParent: Function;
    draftMessageArray: IDraftMessage[];
    setDraftMessageArray: Function;
}


const getNewMessage = (legalTransactionObject:ILegalTransactionFullObject,draftMessageArray:IDraftMessage[]) => {
    let tmpId = Math.min(...draftMessageArray.map(x => x.idDraftMessage)) -1;

    if (tmpId >= 0) {
        tmpId = - 1;
    }

    return {
        DraftMessage: "",
        idDraftMessage: tmpId,
        idDraftMessageState: 1,
        idLegalTransaction: legalTransactionObject.idLegalTransaction,
        idUser: null,
        idContact: Number(sessionStorage.getItem("idContact")),
        Partent_idDraftMessage: null,
        DocumentArray: []
    } as IDraftMessageFullObject
}



export const NewDraftMessage:React.FC<IProps> = (props) => {
    const [parentObject, setPrentObject] = useState<IDraftMessage|undefined>(undefined);
    const [messageObject, setMessageObject] = useState<IDraftMessage>(getNewMessage(props.legalTransactionObject,props.draftMessageArray));
    const [documentArray, setDocumentArray] = useState<IDocument[]>([]);
    //
    const [isOpenNew,setIsOpenNew] = useState(false);
    //
    const [wasSaved,setWasSaved] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [wasSavedSuccessfully,setWasSavedSuccessfully] = useState(true);


    const handleOpen = () => {
        setIsLoading(false);
        setWasSaved(false);
        setMessageObject(getNewMessage(props.legalTransactionObject,props.draftMessageArray));
        (props.idDraftMessageParent !== null) && 
            setPrentObject(props.draftMessageArray.find(x => x.idDraftMessage === props.idDraftMessageParent));
        setIsOpenNew(true);
    }

    const handleClose = () => {
        props.setIdDraftMessageParent(null);
        setPrentObject(undefined);
        setIsOpenNew(false);
    }

    const handleSave = () => {
        let tmpObject = {
            ...messageObject,
            Created_at: new Date().toISOString(),
            DocumentArray: documentArray
        }

        if (parentObject !== undefined) {
            tmpObject.Partent_idDraftMessage = parentObject.idDraftMessage;
        }

        uploadFetch("/draftmessage",true,tmpObject,() => {},setWasSavedSuccessfully,setWasSaved,setIsLoading)
    }


    useEffect(() => {
        if (props.idDraftMessageParent !== null) {
            handleOpen()
        }
    },[props.idDraftMessageParent])



    const contextTsx = () => {
        if (isLoading) {
            return(
                <DialogContent>
                    <CustomCircularProgress/>
                </DialogContent>
            )
        }
        else if (wasSaved) {
            if (wasSavedSuccessfully) {
                return(
                    <>
                        <DialogContent>
                        <Alert severity="success">
                        Ihr Kommentar wurde erfolgreich hocheladen!
                        <br/> Es kann bis zu fünf Minuten dauern, bis der Kommentar gelistet wird. 
                    </Alert>
                        </DialogContent>
                        <DialogActions>
                                <Button variant="contained" onClick={handleClose}>Schließen</Button>
                        </DialogActions>
                    </>
                ) 
            } else {
                return(
                    <>
                        <DialogContent>
                            <Alert severity="error">
                                Es ist ein Fehler aufgeretten!
                                <br/> Die melden Sie sich bei uns!
                            </Alert>
                        </DialogContent>
                        <DialogActions>
                                <Button variant="contained" onClick={handleClose}>Schließen</Button>
                        </DialogActions>
                    </>
                )
            }
        } else {
            return(
                <>
                <DialogContent>
                    { (parentObject !== undefined) && 
                        <>
                        <Paper variant="outlined" sx={{m: 2, backgroundColor: grey[50]}}>
                            <Box sx={{m: 2}}>
                                <i>{parentObject.DraftMessage}</i>
                            </Box>
                        </Paper>
                        </>
                    }

                    <TextField
                        sx={{mt: 2}}
                        label={(parentObject === undefined) ? "Kommentar" : "Antwort"}
                        value={messageObject.DraftMessage}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setMessageObject({...messageObject, DraftMessage: event.target.value})}
                        size="small"
                        fullWidth
                        rows={5}
                        multiline
                        error={messageObject.DraftMessage === ""}
                    />
                    <Box sx={{mt: 5}}/>
                    <DocumentOverview
                        allowUpload
                        allowRemove
                        idLegalRequirement={-1}
                        legalTransactionObject={props.legalTransactionObject}
                        showReadPermission={false}
                        documentArray={documentArray}
                        setDocumentArray={setDocumentArray}
                        title="Doumente zum Kommentar"
                    />


                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>Abbruch</Button>
                    <Button variant="contained" onClick={handleSave} disabled={messageObject.DraftMessage === ""}>Übernehmen</Button>
                </DialogActions>
                </>
            )
        }
    }



    return(
        <>
            <Dialog
                open={isOpenNew}
                onClose={handleClose}
                maxWidth="sm" fullWidth
            >
                <DialogTitle>{(parentObject === undefined) ? "Neuer Kommentar" : "Antwort zu"}</DialogTitle>
                {contextTsx()}
            </Dialog>

            <Button variant="outlined" sx={{float: "right"}} onClick={handleOpen}>Kommentar hinzufügen</Button>
        </>
    )
}
