import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { ContactPage, Download, InsertDriveFile, UploadFile } from "@mui/icons-material";
import { DocumentOverview } from "../../Document/DocumentOverview";
import { ILegalRequirement } from "../../../../Interfaces/ILegalRequirement";
import { IDocument } from "../../../../Interfaces/IDocument";
import { LegalRequirementRequestFiles } from "./LegalRequirementRequestFiles";
import { ILegalTransactionFullObject } from "../../../../Interfaces/ILegalTransaction";
import { LegalRequirementUploadFiles } from "./LegalRequirementUploadFiles";


interface IProps {
    legalTransactionObject: ILegalTransactionFullObject;
    legalRequirementObject: ILegalRequirement;
    setLegalRequirementObject: Function;
    isNotDisabled: boolean;
}


export const LegalRequirementDocuments:React.FC<IProps> = (props) => {
    const [documentArrayClient, setDocumentArrayClient] = useState<IDocument[]>(
        (props.legalRequirementObject.DocumentArrayClient === undefined) ? [] : props.legalRequirementObject.DocumentArrayClient
    );
    const [documentArrayEmployees, setDocumentArrayEmployees] = useState<IDocument[]>(
        (props.legalRequirementObject.DocumentArrayEmployees === undefined) ? [] : props.legalRequirementObject.DocumentArrayEmployees
    );
    //
    const [isOpenClient, setIsOpenClient] = useState(false);
    const [isOpenEmployees, setIsOpenEmployees] = useState(false);
    //
    const [isOpenUpload, setIsOpenUpload] = useState(false);

    const handleCloseClient = () => {
        let foundItem = documentArrayClient.find(x => x.idDocument < 0);

        if (foundItem === undefined) {
            setIsOpenClient(false);
        } else {
            setIsOpenClient(false);
            setIsOpenUpload(true);
        }
    }

    const hanldeOpenEmployees = () => {
        setDocumentArrayEmployees((props.legalRequirementObject.DocumentArrayEmployees === undefined) ? [] : props.legalRequirementObject.DocumentArrayEmployees);
        setIsOpenEmployees(true);
    }

    const handleSave = () => {
        props.setLegalRequirementObject({
            ...props.legalRequirementObject,
            DocumentArrayEmployees: documentArrayEmployees
        } as ILegalRequirement);
        setIsOpenEmployees(false);
    }


    return(
        <>
            <LegalRequirementUploadFiles
                idLegalRequirement={props.legalRequirementObject.idLegalRequirement}
                idContact={Number(sessionStorage.getItem('idContact'))}
                idLegalTransaction={props.legalTransactionObject.idLegalTransaction}
                documentArrayClient={documentArrayClient}
                setDocumentArrayClient={setDocumentArrayClient}
                isOpen={isOpenUpload}
                setIsOpen={setIsOpenUpload}
            />


            <Dialog
                open={isOpenClient}
                onClose={handleCloseClient}
                maxWidth="lg" fullWidth
            >
                <DialogContent>
                    <DocumentOverview
                        key={`DocumentOverview-client`}
                        legalTransactionObject={props.legalTransactionObject}
                        idLegalRequirement={props.legalRequirementObject.idLegalRequirement}
                        documentArray={documentArrayClient}
                        setDocumentArray={setDocumentArrayClient}
                        allowRemove={false}
                        allowUpload={true}
                        title="Ihre Daten"
                        showReadPermission={true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleCloseClient}>Okay</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isOpenEmployees}
                onClose={() => setIsOpenEmployees(false)}
                maxWidth="lg" fullWidth
            >
                <LegalRequirementRequestFiles
                    legalTransaction={props.legalTransactionObject}
                    documentArray={documentArrayEmployees}
                    setIsOpen={setIsOpenEmployees}
                />
            </Dialog>

            {(props.legalRequirementObject.canUploadFilesClient == true) && 
                <IconButton 
                    title="Hochladen und runterladen von eigenen Dateien"
                    size="small" 
                    onClick={() => { setIsOpenClient(true)}}
                    disabled={!props.isNotDisabled}
                ><UploadFile/></IconButton>
            }
            {(documentArrayEmployees.length > 0 && props.legalRequirementObject.canUploadFilesEmployees == true) && 
                <IconButton 
                    title="Runterladen von bereitgestellten Dateien"
                    size="small" 
                    onClick={hanldeOpenEmployees}
                    disabled={!props.isNotDisabled}
                ><Download/></IconButton>
            }
        </>
    )

}