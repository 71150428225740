import React, { useEffect, useState } from "react";
import { Box, Grid, Table, TableBody, Typography, tableCellClasses } from "@mui/material";
import { LegalPhaseRow } from "./LegalPhaseRow";
import { ILegalPhaseFullObject } from "../../../../Interfaces/ILegalPhase";
import { ILegalTransactionFullObject } from "../../../../Interfaces/ILegalTransaction";

interface IProps {
    legalTransaction: ILegalTransactionFullObject;
    legalPhaseFullObjectArray: ILegalPhaseFullObject[];
    setLegalPhaseFullObjectArray: Function;
}


export const LegalPhaseMain:React.FC<IProps> = (props) => {
    const [legalPhaseFullObjectArray, setLegalPhaseFullObjectArray] = useState<ILegalPhaseFullObject[]>([]);

    useEffect(() => {
        /// map: LegalPhase aufbereiten, falls Postion nicht vorhanden
        setLegalPhaseFullObjectArray([
            ...legalPhaseFullObjectArray.map((currentLegalPhase,idx) =>
                (currentLegalPhase.Position !== null) ? currentLegalPhase
                : {...currentLegalPhase, Position: idx+1 } 
            )
        ])
        /// end: map
    },[props.legalPhaseFullObjectArray])

    return(
        <>
            <Typography variant="h5">
                Aktueller Statusverlauf
            </Typography>
            
            <Box sx={{float: "right", mb: 3}}>
                Fragen zum Statusverlauf? Dann schreiben Sie uns eine <a href={`mailto:bearbeitung@holland-kreusslein.de?subject=[${(props.legalTransaction.TransactionNumberArray === null) ? props.legalTransaction.Title : props.legalTransaction.TransactionNumberArray[0]}]: Statusverlauf`}>E-Mail</a>!
            </Box>

            <Grid container spacing={1}>
                {props.legalPhaseFullObjectArray.sort((a,b) => Number(a.Position) < Number(b.Position) ? -1 : 1).map(x =>
                    <LegalPhaseRow
                        key={`idLegalPhase-${x.idLegalPhase}`}
                        legalTransaction={props.legalTransaction}
                        legalPhaseFullObject={x}
                        legalPhaseFullObjectArray={props.legalPhaseFullObjectArray}
                        setLegalPhaseFullObjectArray={setLegalPhaseFullObjectArray}
                    />
                )}
            </Grid>
        </>
    )
}