import React, { useState } from "react";
import { Dialog, IconButton, MenuItem, TableCell, TableRow, TextField } from "@mui/material";
import { Delete, Download } from "@mui/icons-material";
import { IDocument } from "../../../Interfaces/IDocument";
import { getFetch } from "../../../hooks/useFetch";
import { ILegalTransactionFullObject } from "../../../Interfaces/ILegalTransaction";
import { LegalRequirementRequestFiles } from "../LegalTransaction/LegalPhase/LegalRequirementRequestFiles";


interface IProps {
    legalTransactionObject: ILegalTransactionFullObject;
    documentObject: IDocument;
    documentArray: IDocument[];
    setDocumentArray: Function;
    allowRemove: boolean;
    showReadPermission: boolean;
    handleClose?: Function;
}


export const DocumentRow:React.FC<IProps> = (props) => {
    const [documentObject, setDocumentObject] = useState(props.documentObject);
    //
    const [isOpenRequestFile, setIsOpenRequestFile] = useState(false);

    const base64Download = (localDocumentObject:IDocument) => {
        console.log("LEL 1");
        if (localDocumentObject.Data !== undefined) {
            console.log("LEL 2");
            let byteCharacters = atob(localDocumentObject.Data);
            // Each character's code point (charCode) will be the value of the byte. 
            // We can create an array of byte values by applying this using the .charCodeAt method for each character in the string.
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            //You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
            let byteArray = new Uint8Array(byteNumbers);
            // This in turn can be converted to a BLOB by wrapping it in an array and passing it to the Blob constructor.
            let blob = new Blob([byteArray], {type: "text/csv"});
            // Erstelle Link zum BLOB
            let blobUrl = URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = blobUrl;
            a.download = localDocumentObject.FileName;
            a.click();
        }
    }

    const wrapperDownload = (localDocumentObject:IDocument) => {
        setDocumentObject(localDocumentObject);
        base64Download(localDocumentObject);
    }

    const handleDownload = () => {
        if (documentObject.Data !== undefined) {
            base64Download(documentObject);
        }
        else {
            //getFetch("document/",documentObject.idDocument,wrapperDownload);
            setIsOpenRequestFile(true);
        }
    }

    const handleRemove = () => {
        props.setDocumentArray([
            ...props.documentArray.filter(x => x.idDocument !== documentObject.idDocument)
        ]);
    }

    const handleChangePermission = (event:React.ChangeEvent<HTMLInputElement>) => {
        let updatedObject = { ...documentObject, everybodyCanRead: (event.target.value === "true") };
        props.setDocumentArray([
            ...props.documentArray.map(x => x.idDocument === updatedObject.idDocument ? updatedObject : x)
        ]);
        setDocumentObject(updatedObject)
    }

    return(
        <>
            <Dialog
                open={isOpenRequestFile}
                onClose={() => setIsOpenRequestFile(false)}
                maxWidth="lg" fullWidth
            >
                <LegalRequirementRequestFiles
                    idDocument={documentObject.idDocument}
                    legalTransaction={props.legalTransactionObject}
                    documentArray={props.documentArray.filter(x => x.idDocument > 0)}
                    setIsOpen={setIsOpenRequestFile}
                    handleClose={props.handleClose}
                />
            </Dialog>

            <TableRow>
                <TableCell>{(documentObject.idDocument < 0) ? <>Neu</> : documentObject.idDocument}</TableCell>
                
                    {(props.showReadPermission) &&

                        <TableCell sx={{width: 200}}>
                        {(documentObject.idDocument < 0)
                        ?   <TextField
                                select
                                label="Zugriff"
                                sx={{width: 200}}
                                size="small"
                                value={(documentObject.everybodyCanRead) ? "true" : "false"}
                                onChange={handleChangePermission} 
                            >
                                <MenuItem key={`everybodyCanRead-false`} value="false">Nur ich (und die Kanzlei)</MenuItem>
                                <MenuItem key={`everybodyCanRead-true`} value="true">Alle Beteiligte</MenuItem>
                            </TextField>
                        :
                            (documentObject.everybodyCanRead) ? "Alle Beteiligte" : "Nur ich (und die Kanzlei)"
                        }
                        </TableCell>
                    }
                
                <TableCell>{documentObject.FileName}</TableCell>
                <TableCell>
                    <IconButton onClick={handleDownload}><Download/></IconButton>
                    {(documentObject.idDocument < 0 || props.allowRemove)
                     && <IconButton onClick={handleRemove}><Delete/></IconButton>
                    }
                </TableCell>
            </TableRow>
        </>
    )
}