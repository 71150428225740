import React, { useState } from "react";
import { IDocument } from "../../../../Interfaces/IDocument";
import { Alert, Button, DialogActions, DialogContent, DialogTitle, Link, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { ILegalTransactionFullObject } from "../../../../Interfaces/ILegalTransaction";
import { ISystem } from "../../../../Interfaces/ISystem";
import { uploadFetch } from "../../../../hooks/useFetch";
import { CustomCircularProgress } from "../../../generic/CustomCircularProgress";
import { DataGrid, GridColDef, GridRowSelectionModel, deDE } from "@mui/x-data-grid";


interface IProps {
    legalTransaction: ILegalTransactionFullObject;
    documentArray: IDocument[];
    setIsOpen: Function;
    idDocument?: number;
    handleClose?: Function;
}

const columns: GridColDef[] = [
    { field: 'FileName', headerName: 'Dateiname', flex: 1 },
    
];

export const LegalRequirementRequestFiles:React.FC<IProps> = (props) => {
    const [currentPage,setCurrentPage] = useState(0);
    const [isLoading] = useState(false);
    const [wasSuccessfully, setWasSuccessfully] = useState(true);

    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>(
        (props.idDocument == undefined) ? [] : [props.idDocument]
    );


    const handleRequestFiles = () => {
        sessionStorage.setItem("requested_data","true")
        let uploadSync:ISystem = {
            idContact: Number(sessionStorage.getItem("idContact")),
            idLegalTransaction: props.legalTransaction.idLegalTransaction,
            SyncType: "RequestFiles",
            Data: rowSelectionModel
        }
        uploadFetch("/system",true,uploadSync,(a:any) => {setCurrentPage(1)},setWasSuccessfully)
    }


    if (isLoading) { return <CustomCircularProgress/> }
    else if (currentPage === 0) {
        return(
            <>
                <DialogTitle>Dokumente anfragen</DialogTitle>
                <DialogContent>
                    Bitte wählen Sie die Dokumente aus, welche sie anfragen möchten.
                    {/**
                     * <br/>Nach erfolgreicher Prüfung der Anfrage werden die Dokumente unter {(props.handleClose === undefined) ? <i>Meine Dokumente</i> : <Link style={{cursor: "pointer"}} onClick={() => props.handleClose!()}><i>Meine Dokumente</i></Link>}  auf der Startseite einmalig bereitgestellt.
                     */}
                    
                    <DataGrid
                        sx={{mt: 2}}
                        autoHeight
                        checkboxSelection 
                        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                        rows={props.documentArray}
                        onRowSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                          }}
                        rowSelectionModel={rowSelectionModel}
                        columns={columns}
                        getRowId={(row) => row.idDocument}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                    />
                    {/*
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nr.</TableCell>
                                <TableCell>Name</TableCell>
                            </TableRow>
    
                        </TableHead>
                        <TableBody>
                            {props.documentArray.map((x,i) =>
                                <TableRow>
                                    <TableCell>{i+1}</TableCell>
                                    <TableCell>{x.FileName}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    */}

                </DialogContent>
                <DialogActions>
                        <Button variant="outlined" onClick={() => props.setIsOpen(false) }>Abbruch</Button>
                        <Button variant="contained" onClick={handleRequestFiles} disabled={rowSelectionModel.length === 0}>Anfragen</Button>
                </DialogActions>
            </>
        )
    }
    else if (currentPage === 1 && wasSuccessfully) {
        return(
            <>
                <DialogTitle>Dokumente anfragen</DialogTitle>
                <DialogContent>
                    <Alert severity="success">
                        Die Dokumente wurden erfolgreich angefragt!
                        <br/>Sie erhalten in den nächsten <b>5 Minuten</b> Ihre Dokumente <b>per E-Mail</b>.
                        {/*
                            <br/>Die angeforderten Dokumente werden in den <b>nächsten 5 Minuten in {(props.handleClose === undefined) ? <i>Meine Dokumente</i> : <Link style={{cursor: "pointer"}} onClick={() => props.handleClose!()}><i>Meine Dokumente</i></Link>} </b> dieses Portals verfügbar sein. Sie erhalten eine E-Mail, sobald die Dokumente bereitgestellt sind.
                        */}
                        
                    </Alert>
                </DialogContent>
                <DialogActions>
                        <Button variant="contained" onClick={() => props.setIsOpen(false) }>Schließen</Button>
                </DialogActions>
            </>
        )
    }
    else if (currentPage === 1 && !wasSuccessfully) {
        return(
            <>
                <DialogTitle>Dokumente anfragen</DialogTitle>
                <DialogContent>
                    <Alert severity="error">
                        Es ist ein Fehler aufgeretten!
                        <br/> Bitte melden Sie sich bei uns!
                    </Alert>
                </DialogContent>
                <DialogActions>
                        <Button variant="contained" onClick={() => props.setIsOpen(false) }>Schließen</Button>
                </DialogActions>
            </>
        )
    }
    else {
        return <>Fehler!</>
    }

}