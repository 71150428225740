import React, { useCallback, useRef, useState } from "react";
import { Card, CardContent, IconButton, Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { DocumentRow } from "./DocumentRow";
import { UploadFile } from "@mui/icons-material";
import { IDocument } from "../../../Interfaces/IDocument";
import { IMIMEType } from "../../../Interfaces/IMIMEType";
import { useSelector } from "react-redux";
import { State } from "../redux/mainReducer";
import { useDropzone } from "react-dropzone";
import { ILegalTransactionFullObject } from "../../../Interfaces/ILegalTransaction";
import { useNavigate } from "react-router-dom";


interface IProps {
    legalTransactionObject: ILegalTransactionFullObject;
    idLegalRequirement: number;
    documentArray: IDocument[];
    setDocumentArray: Function;
    allowUpload: boolean;
    allowRemove: boolean;
    title: string;
    showReadPermission: boolean;
    handleClose?: Function;
}


export const DocumentOverview: React.FC<IProps> = (props) => {
    const navigation = useNavigate();
    //
    const mimeTypeArray = useSelector((state: State) => state.mimeTypeArray.storeAcademicTitle);
    const [enableUpload, setEnableUpload] = useState(false);
    //
    const inputUpload = useRef<HTMLInputElement | null>(null);


    async function fileToBase64(file: File) {
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });
        return String(result_base64).split(",")[1];
    }

    const handleFilesUpload = async (acceptedFiles: File[]) => {
        let uploadArray: IDocument[] = [];
        let minIdOffset = -1;

        if (props.documentArray.length > 0) {
            let minIdArray = Math.min(...props.documentArray.map(x => x.idDocument));

            if (minIdArray <= minIdOffset) {
                minIdOffset = minIdArray - 1;
            }
        }

        let index = 0;


        for (let currentFile of acceptedFiles) {
            let fileName: string = String(currentFile["name"]);
            let mimeType: IMIMEType | undefined = mimeTypeArray.find(y => y.MIMEType === currentFile["type"]);

            if (mimeType !== undefined) {
                uploadArray.push({
                    idDocument: minIdOffset - index,
                    FileName: fileName,
                    idMIMEType: mimeType.idMIMEType,
                    Data: await fileToBase64(currentFile),
                    idLegalRequirement: props.idLegalRequirement,
                    everybodyCanRead: false,
                    idContact: Number(localStorage.getItem("idContact"))
                } as IDocument)
            }
            index = index + 1;
        }


        /*
        acceptedFiles.map((currentFile,idx) => {
            let fileName:string = String(currentFile["name"]);
            let mimeType: IMIMEType | undefined = mimeTypeArray.find(y => y.MIMEType === currentFile["type"]);
            
            if (mimeType !== undefined) {
                uploadArray.push({
                    idDocument: minIdOffset - idx,
                    FileName: fileName,
                    idMIMEType: mimeType.idMIMEType,
                    Data: await fileToBase64(currentFile)
                } as IDocument)
            }
            
        })
        */
        props.setDocumentArray([
            ...props.documentArray,
            ...uploadArray
        ])

    }

    const handleNormalUpload = (files:null|FileList) => {
        if (files !== null) {
            let acceptedFiles:File[] = Array.from(files);
            handleFilesUpload(acceptedFiles);
        }
    }

    const onDrop = useCallback((acceptedFiles: File[]) => {
        //setNewFileArray(acceptedFiles);
        //callBackDriver(acceptedFiles);
        //setAddNew(false);
        handleFilesUpload(acceptedFiles);
        setEnableUpload(false);
    }, [props.documentArray]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        noClick: true,
    });

    const dropZoneTSX = () => {
        return (
            <div>
                <input {...getInputProps()} accept={String(mimeTypeArray.map(x => x.MIMEType).join(", "))} />
                <Card>
                    <CardContent>
                        <Typography variant="body2">
                            Dokument(e) hier reinziehen
                        </Typography>
                        Erlaubte Datentypen
                        <br />
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Datentyp</TableCell>
                                    <TableCell>Datenendung</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {mimeTypeArray.map(x =>
                                    <TableRow>
                                        <TableCell>{x.MIMEType}</TableCell>
                                        <TableCell>{x.FileExtension}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </div>
        )
    }

    return (
        <div {...getRootProps()}>
            <input 
             hidden 
             ref={inputUpload} 
             type="file" 
             accept={String(mimeTypeArray.map(x => x.MIMEType).join(", "))} 
             onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNormalUpload(event.target.files)} 
            />
            <Typography variant="h5">
                {props.title}
                {(props.allowUpload) && 
                    <IconButton 
                     title="Daten über Auswähl hochladen"
                     onClick={() => (inputUpload !== null) && inputUpload.current?.click()} 
                     sx={{float: "right"}}
                    >
                        <UploadFile/>
                    </IconButton>
                }
                {(props.allowUpload) && <><br/><Typography variant="caption">(Hochladen über reinziehen der Daten möglich)</Typography></>}
            </Typography>
            {/*
            <Typography>
                Sie können hier Dokumente anfragen, welche nach einer Prüfung, unter {(props.handleClose === undefined) ? <i>Meine Dokumente</i> : <Link style={{cursor: "pointer"}} onClick={() => props.handleClose!()}><i>Meine Dokumente</i></Link>}  auf der Startseite bereitgestellt werden.
            </Typography>
             */}


            {((isDragActive || enableUpload) && props.allowUpload) && dropZoneTSX()}

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nr.</TableCell>
                        {(props.showReadPermission) && <TableCell>Zugriff</TableCell> }
                        <TableCell>Dateiname</TableCell>
                        <TableCell>Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.documentArray.map(x =>
                        <DocumentRow
                            key={`idDocument-${x.idDocument}`}
                            legalTransactionObject={props.legalTransactionObject}
                            documentObject={x}
                            documentArray={props.documentArray}
                            setDocumentArray={props.setDocumentArray}
                            allowRemove={props.allowRemove}
                            showReadPermission={props.showReadPermission}
                            handleClose={props.handleClose}
                        />
                    )}
                </TableBody>
            </Table>
        </div>
    )

}