import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import Login from "./Login";
import { CustomCircularProgress } from "../generic/CustomCircularProgress";
import NavBarPersistent from "../private/NavBarPersistent";
import { LegalTransactionOverview } from "../private/LegalTransaction/LegalTransactionOverview";
import { IConfig } from "../../Interfaces/IConfig";
import { CopyrightLextorByte } from "../../CopyrightLextorByte";
import { LoginDriver } from "../Login/LoginDriver";
import { ClientEdit } from "../private/Client/ClientEdit";
import { LoginData } from "../Login/LoginData";


interface IProps {
    msg?: string;
    children: React.ReactElement;
    configObject: IConfig;
}


const SecurityDriver:React.FC<IProps> = (props) => {
    const [isLoading,setIsLoading] = useState(true);
    const [isLogged,setIsLogged] = useState(false);
    const [msg, setMsg] = useState<string | null>(null);


    useEffect(() => {
        let sessionToken = sessionStorage.getItem('SessionToken');

        if (sessionToken === "" || sessionToken === null) {
            setIsLogged(false);
            setIsLoading(false);
        } else {
            fetch("/api/oauth",{
                method: "GET",
                headers : { "Authorization" : "Bearer " + sessionStorage.getItem('SessionToken') }
            })
            .then(res => {
                if (res.status == 200) {
                    setIsLogged(true);
                    setIsLoading(false);
                } else {throw Error(String(res.status))}
            })
            .catch( error => {
                if (String(error).includes("401")) {
                    setMsg("Ihre Session ist abgelaufen.")
                } else if (String(error).includes("404")) {
                    setMsg("Die Anmeldedaten konnten nicht gefunden werden. Bitte überprüfen Sie den Anmeldename und das Passwort");
                } else if (String(error).includes("403")) {
                    setMsg(`Ihr Konto wurde deaktiviert!`);
                } else {
                    setMsg(`Es ist ein unbekannter Fehler aufgeretten. Möglicherweise ist der Dienst vorübergehend offline.`);
                }
                setIsLogged(false);
                setIsLoading(false);
          })
        }
    },[])

    if (isLoading) { return <CustomCircularProgress /> }
    else if (isLogged && sessionStorage.getItem("passwordResetRequired") !== "true") { 
        return props.children
    }
    else if (isLogged && sessionStorage.getItem("passwordResetRequired") === "true") { 
        return <LoginData configObject={props.configObject}/>
    }
    else { 
        return(
            <>
                {/* <Login configObject={props.configObject} msg={msg} setIs2FALogged={() => {}} /> */}
                <LoginDriver configObject={props.configObject} msgParent={msg}/>
                <CopyrightLextorByte configObject={props.configObject}/>
            </>
        )
    }
         

}
export default SecurityDriver;