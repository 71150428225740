import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { AccountBalance, BackupTable, Logout, Person } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Button, Menu, MenuItem, Tooltip, useMediaQuery } from '@mui/material';
import { IConfig } from '../../Interfaces/IConfig';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    children?: React.ReactElement;
    configObject: IConfig;
  }

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function NavBarPersistent(props:Props) {
  const isDesktop = useMediaQuery('(min-width:600px)');
  const theme = useTheme();
  const navigation = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {(isDesktop) ? `DiNo - ${props.configObject.company_name}` : "DiNo"}
          </Typography> 
          
          <Box display='flex' flexGrow={1} />
          <Typography sx={{mr: 2}}> {props.configObject.version} </Typography>
          <IconButton title="Logout" onClick={() => { sessionStorage.removeItem("SessionToken"); window.location.reload(); }}><Logout/></IconButton>
          {/*
          <Button variant='contained' color='inherit' onClick={() => { sessionStorage.removeItem("SessionToken"); window.location.reload(); }}>Abmelden</Button>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Einstellungen öffnen">
              <IconButton onClick={(event: React.MouseEvent<HTMLElement>)  => setAnchorElUser(event.currentTarget)} sx={{ p: 0 }}>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={() => setAnchorElUser(null)}
            >
              <MenuItem onClick={() =>{sessionStorage.removeItem("SessionToken"); window.location.reload(); }} >
                <Typography textAlign="center">Abmelden</Typography>
              </MenuItem>
            </Menu>
          </Box>
          */}

          
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
            <ListItemButton onClick={() => navigation("/")}>
                <ListItemIcon><AccountBalance/></ListItemIcon>
                <ListItemText>Meine Vorgänge</ListItemText>
            </ListItemButton>
        </List>
        <Divider />
        <List>
            <ListItemButton onClick={() => navigation("/account")}>
                <ListItemIcon><Person/></ListItemIcon>
                <ListItemText>Mein Konto</ListItemText>
            </ListItemButton>
        </List>
            { /* 
            <ListItemButton onClick={() => navigation("/private")}>
                <ListItemIcon><Person/></ListItemIcon>
                <ListItemText>Kaka</ListItemText>
            </ListItemButton>
        </List>
        <Divider />
        <List>
            <ListItemButton onClick={() => navigation("/private")}>
                <ListItemIcon><BackupTable/></ListItemIcon>
                <ListItemText>In den Mund</ListItemText>
            </ListItemButton>
        </List>
            */}

        <List>

        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Box sx={{ width: "100%" }}>
          {props.children}
        </Box>
      </Main>
    </Box>
  );
}